@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;1,600&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100vh;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  /* background-color: #fff; */
}
.contentauth{
   height: 100vh;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #fff;
}
.app {
  display: flex;
  position: relative;
}
::-webkit-scrollbar {
  height: 4px !important;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle On Hover  */
::-webkit-scrollbar-track:hover {
  background: #555;
}
.css-1djprdn,
.css-uv7eon-MuiTypography-root {
  color: #1f2a40 !important;
}
.css-1476h24,
.css-1rv476z-MuiInputBase-input-MuiFilledInput-input {
  background-color: #11379F !important;
}
.css-t6v7e2,
.css-z3wugd-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root {
  background-color: #11379F !important;
}
.PhoneInput  {
  background-color: #11379F !important;
}
.css-wnx1cx-MuiButtonBase-root-MuiButton-root.Mui-disabled ,.css-190scks.Mui-disabled {
  background-color: #11379F !important;
}

.css-1fl46ob{
min-width: 35vw !important;
background-color: #11379F !important;
}
.css-502gu7{
 background-color: #11379F !important;
}
 
.crsr {
  cursor: pointer;
}
.Mui-focused {
  color: #e0e0e0 !important;
}
.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #2c2c2c inset !important;
  -webkit-text-fill-color: #fff;
  caret-color: #fff;
  border-radius: inherit;
}
.css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px #2c2c2c inset !important;
}
.poshide {
  visibility: hidden !important;
}
.css-1kt8ah5-MuiDataGrid-root .MuiDataGrid-columnHeader--moving {
  background-color: transparent !important;
}
.errborder::after {
  border-color: red !important;
  color: #fff !important;
}
.css-havevq-MuiSvgIcon-root {
  color: #fff !important;
  margin-left: 0.5rem !important;
}
.css-13qp4b7-MuiButtonBase-root-MuiButton-root:hover {
  text-decoration: none;
  background-color: none !important;
}
.css-1ycxt2a-MuiButtonBase-root-MuiIconButton-root {
  border-radius: 10px !important;
}
.css-19ixu4k {
  background-color: #1f2a40 !important;
  border-radius: 10px !important;
}
.css-1qxadfk-MuiPaper-root-MuiDialog-paper {
  background-color: #1f2a40 !important;
}
.delicon {
  color: #4cceac !important;
  font-size: 2rem !important;
}
/* .messagetoltip {
  background-color: #ff5c5c !important;
  border-radius: 5px !important;
}
.messagetoltipsuccess {
  background-color: #00d100 !important;
  border-radius: 5px !important;
} */
.css-11fo197 {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.28571rem;
  color: #fff !important;
  margin-left: 0.5rem !important;
}
.css-11jeb99 {
  margin: 20px;
  height: 85% !important;
  overflow: auto !important;
}
.css-1kt8ah5-MuiDataGrid-root
  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)
  > .MuiDataGrid-cel {
  overflow: auto !important;
}
.popuptext {
  color: #4cceac;
  font-size: medium;
}
.ql-snow .ql-stroke {
  stroke: #fff !important;
}
.ql-snow .ql-picker {
  color: #fff !important;
}
.ql-snow .ql-picker-options .ql-picker-item {
  color: #4cceac !important;
}
/* #11379F */
.pro-sidebar {
  height: 110% !important;
}
.PhoneInputInput {
  background-color: #11379F !important;
  flex: 1 1;
  min-width: 0;
  padding: 0.8rem !important;
}
.PhoneInputCountrySelect {
  background-color: #11379F !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff !important;
  opacity: 1; /* Firefox */
}