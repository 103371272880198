.css-bpxjxr-MuiFormLabel-root-MuiInputLabel-root {
  position: absolute;
  left: 10px !important;
  top: 9px !important;
}
.css-oc1ihe-MuiFormLabel-root-MuiInputLabel-root{
    left: 13px !important;
}
.css-bpxjxr-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
color: #f3f5f9 !important;
}
.system{
  display: flex;
  justify-content: space-between;
}
.tags-input-container{
    /* border: 2px solid #000; */
    padding: .5em;
    border-radius: 3px;
    width: auto;
    margin-top: 1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
}

.tag-item{
    background-color: #6870fa;
    display: inline-block;
    padding: .5em .75em;
    border-radius: 20px;
}
.tag-item .close{
    height: 20px;
    width: 20px;
    background-color: #141B2D;
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
    cursor: pointer;
}

.tags-input{
    flex-grow: 1;
    padding: .5em 0;
    border: none;
    outline: none;
    background-color: #323848;
   caret-color: #fff;
   color: #f3f5f9;
}
.selected{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, #3da58a 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}